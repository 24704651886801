<template>
  <header class="full-screen-width" style="margin-top: -32px">
    <div class="border"></div>

    <div class="flex flex-col lg:flex-row pl-0 lg:pl-[3%]">
      <div
        class="w-full px-4 lg:px-0 lg:w-5/12 text-left bg-white flex flex-col md:items-center lg:items-start justify-center lg:pl-[4px]"
      >
        <h1 class="beam-h1 max-w-xl pb-8 text-left lg:mt-0 mt-8">
          Make every day count with a purpose-driven career
        </h1>
        <div
          class="max-w-xl lg:text-xl text-lg font-normal text-balance leading-7 mb-2 lg:mb-7"
        >
          Join a pioneering company on a mission to give everyone access to
          human-centred welfare services.
        </div>
        <div
          class="max-w-xl lg:text-xl sm:text-lg font-normal text-balance leading-7 mb-8 lg:mb-0"
        >
          Collaborate with a high-performance team using tech and AI to create
          positive social impact.
        </div>
        <div
          class="mb-3 mt-8 md:mb-0 flex md:w-80 justify-center md:justify-normal"
        >
          <a
            class="button button-primary hidden button-large w-full mt-2 mb-8 md:mb-2 text-base"
            href="#positions"
            >Browse our <span class="js-open-positions"></span> open roles</a
          >
        </div>
      </div>

      <div class="w-full lg:w-7/12 relative flex">
        <img
          class="w-full h-full clip-path-mobile lg:clip-path-desktop"
          :src="heroImageUrl"
          alt="The Beam team at our 2023 company retreat"
        />
        <div
          class="p-2.5 bg-black/10 w-5/6 lg:w-3/6 absolute rounded bottom-4 left-1/2 transform -translate-x-1/2 backdrop-blur-[10px] flex flex-col lg:flex-row items-center justify-center gap-0 lg:gap-2.5"
        >
          <img
            class="mb-0.5 md:mb-0"
            :src="`/icons/wired-icon-bg-overlay.svg`"
            alt="Wired Icon"
          />
          <div class="text-white text-base font-bold text-center lg:text-left">
            One of London's 10 Hottest Startups
          </div>
        </div>
      </div>
    </div>
  </header>
  <div>
    <main>
      <div class="full-screen-width bg-navy-100">
        <div class="flex flex-col md:px-0 sm:flex-row py-12 justify-center">
          <div class="flex-shrink-0 sm:mt-0 beam-container">
            <div
              class="md:text-5xl text-2xl font-bold pb-10 flex justify-center"
            >
              Why choose a career at Beam
            </div>
            <div class="video-wrapper relative">
              <div
                class="video-overlay absolute top-0 z-10 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-50 cursor-pointer"
              >
                <img
                  :src="`/icons/play-hover.svg`"
                  alt="Play"
                  class="custom-play-button w-16 h-16 md:w-24 md:h-24"
                />
              </div>

              <iframe
                width="859"
                height="535"
                src="https://www.youtube.com/embed/UQacQ9mvgII?modestbranding=1"
                title="Working at beam"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                style="border-radius: 10px"
              ></iframe>
            </div>
          </div>
        </div>

        <!-- left align text -->
        <section>
          <div class="beam-container">
            <div class="flex flex-col justify-center">
              <landing-left-text class="mt-8 sm:mt-16 lg:mt-26">
                <template #title>
                  <div
                    class="text-2xl sm:text-3xl md:text-4xl text-left max-w-lg"
                  >
                    <span class="yellow-underline-landing-left"
                      >Be energised</span
                    >
                    by the most purposeful work of your career
                  </div>
                </template>

                <template #body>
                  <div
                    class="flex flex-col gap-2.5 text-base md:text-lg max-w-md"
                  >
                    <p>
                      Beamers have changed the
                      <a
                        class="underline"
                        target="_blank"
                        href="https://beam.org/company-impact"
                      >
                        lives of thousands of underserved people</a
                      >. We're now impacting far more with software we build for
                      government - like
                      <a
                        class="underline"
                        target="_blank"
                        href="https://magicnotes.ai"
                        >Magic Notes</a
                      >.
                    </p>
                  </div>
                </template>

                <template #image>
                  <div class="max-w-md">
                    <img
                      class="rounded-xl w-full h-auto"
                      :src="landingLeft1Url"
                      alt="Adam"
                    />
                  </div>
                </template>
              </landing-left-text>

              <landing-left-text
                :image-left="true"
                :text-right-align="false"
                class="mt-16 sm:mt-20 lg:mt-32"
              >
                <template #title>
                  <div
                    class="text-2xl sm:text-3xl md:text-4xl text-left max-w-md"
                  >
                    <span class="yellow-underline-landing-left"
                      >Accelerate your career</span
                    >
                    in a fast-paced startup
                  </div>
                </template>

                <template #body>
                  <div
                    class="flex flex-col gap-2.5 max-w-md text-base md:text-lg"
                  >
                    <p>
                      At Beam, you'll learn fast with high levels of ownership,
                      plus the tools you need to do it. Making an outsized
                      impact leads to fast progression.
                    </p>
                  </div>
                </template>

                <template #image>
                  <div class="max-w-md">
                    <img
                      class="rounded-xl w-full h-auto"
                      :src="landingLeft2Url"
                      alt="Dan presentation"
                    />
                  </div>
                </template>
              </landing-left-text>

              <landing-left-text class="mt-16 sm:mt-20 lg:mt-32">
                <template #title>
                  <div
                    class="text-2xl sm:text-3xl md:text-4xl max-w-md text-left"
                  >
                    Work with an
                    <span class="yellow-underline-landing-left"
                      >ambitious,
                    </span>
                    <span class="yellow-underline-landing-left"
                      >supportive team</span
                    >
                  </div>
                </template>

                <template #body>
                  <div
                    class="flex flex-col gap-2.5 text-base md:text-lg max-w-md"
                  >
                    <p>
                      People with big ambitions and humility come to Beam to do
                      the most important work of their careers. Sound like you?
                    </p>
                  </div>
                </template>

                <template #image>
                  <div class="max-w-md">
                    <img
                      class="rounded-xl w-full h-auto"
                      :src="landingLeft3Url"
                      alt="Beam team in office joe"
                    />
                  </div>
                </template>
              </landing-left-text>
            </div>
          </div>
        </section>

        <!-- Testimonials -->
        <section class="mt-16">
          <div class="md:beam-container w-full bg-pale-grey-100 py-10 sm:py-12">
            <div class="beam-h2 text-center text-pretty mb-4">
              Why Beamers love working here
            </div>

            <div
              class="grid grid-cols-1 md:grid-cols-2 gap-x-7 border md:border-none"
            >
              <careers-testimonial
                v-for="(testimonial, index) in testimonials"
                :key="testimonial.id"
                :testimonial="testimonial"
                class="rounded-lg"
                :isLast="index === testimonials.length - 1"
              ></careers-testimonial>
            </div>
          </div>
        </section>

        <!-- Gallery" -->
        <div class="w-full relative overflow-hidden">
          <gallery-continuous
            :gallery-images="galleryImages"
          ></gallery-continuous>
        </div>

        <!-- Leading the way -->
        <section class="mt-16">
          <div class="beam-container h-full flex justify-center">
            <div class="pt-10 pb-5">
              <div class="beam-h2 text-center mb-3">
                Leading the way in tech for good
              </div>
              <div class="text-xl text-center py-3">
                Beam is bringing tech and AI to the roughly $1Tn that OECD
                governments are spending each year on<br />welfare services. We
                serve over 100 government customers - and growing.
              </div>
              <div
                class="flex flex-col md:flex-row pt-8 gap-3 justifty-between"
              >
                <div
                  class="relative w-full bg-white border rounded-xl p-6 flex flex-col"
                >
                  <div class="flex-grow flex items-center justify-center">
                    <img
                      class="object-contain"
                      :src="`/icons/wired-icon.svg`"
                      alt="Wired Icon"
                    />
                  </div>
                  <div class="text-base font-bold text-center mt-6">
                    London's 10 Hottest Startups
                  </div>
                </div>

                <div
                  class="relative w-full bg-white border rounded-xl p-6 flex flex-col"
                >
                  <div class="flex-grow flex items-center justify-center">
                    <img
                      class="object-contain"
                      :src="`/icons/linkedin-icon.svg`"
                      alt="LinkedIn Icon"
                    />
                  </div>
                  <div class="text-base font-bold text-center mt-6">
                    UK's Top 15 startups
                  </div>
                </div>

                <div
                  class="relative w-full bg-white border rounded-xl p-6 flex flex-col"
                >
                  <div
                    class="flex-grow flex sm:h-3/6 items-center justify-center"
                  >
                    <img
                      class="object-contain"
                      :src="`/icons/mit-icon.svg`"
                      alt="MIT Icon"
                    />
                  </div>
                  <div class="text-base font-bold text-center mt-6">
                    Best financial inclusion organisation in Europe
                  </div>
                </div>

                <div
                  class="relative w-full bg-white border rounded-xl p-6 flex flex-col"
                >
                  <div class="flex-grow flex items-center justify-center">
                    <img
                      class="object-contain"
                      :src="`/icons/evening-standard-icon.svg`"
                      alt="Evening Standard Icon"
                    />
                  </div>
                  <div class="text-base font-bold text-center mt-6">
                    Tech Week 20 Hottest British Startups
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <!-- B-Corp companies supporting -->
        <section class="mt-1">
          <div class="md:beam-container sm:w-full h-full flex justify-center">
            <div
              class="relative h-fit bg-white border rounded-none md:rounded-xl overflow-hidden"
            >
              <div class="p-7 flex flex-col justify-center gap-5 relative z-10">
                <img
                  class="mx-auto"
                  :src="`/icons/b-corp-icon.svg`"
                  alt="B corp certified"
                />
                <div class="beam-h2 text-center">
                  Proud to be a certified
                  <span class="whitespace-nowrap">B Corp™</span>
                </div>
                <div
                  class="text-base md:text-xl text-center text-balance mb-24"
                >
                  As a B Corp, Beam meets high standards of social and
                  environmental governance. We're among a growing list of
                  companies now using business as a force for good.
                </div>
                <div
                  class="text-xl md:text-2xl font-bold text-center text-balance mb-4"
                >
                  And we’re backed by leading, mission-driven investors,
                  including the founders of:
                </div>
                <div
                  class="flex flex-wrap justify-center md:flex-row md:justify-between px-3 gap-y-2 pb-2"
                >
                  <img
                    class="p-1"
                    :src="`/icons/booking-icon.svg`"
                    alt="booking icon"
                  />
                  <img
                    class="p-1"
                    :src="`/icons/dropbox-icon.svg`"
                    alt="dropbox icon"
                  />
                  <img
                    class="p-1"
                    :src="`/icons/calm-icon.svg`"
                    alt="calm icon"
                  />
                  <img
                    class="p-1"
                    :src="`/icons/shazam-icon.svg`"
                    alt="shazam icon"
                  />
                  <img
                    class="p-1"
                    :src="`/icons/multiverse-icon.svg`"
                    alt="multiverse icon"
                  />
                  <img
                    class="p-1"
                    :src="`/icons/gocardless-icon.svg`"
                    alt="gocardless icon"
                  />
                </div>
              </div>
              <div
                class="absolute w-[130%] h-[130%] left-1/2 md:bottom-[-90%] bottom-[-85%] -translate-x-1/2 bg-azure-10/5 sm:curved-top-mobile md:curved-top"
                :style="ellipsisBorderStyle"
              ></div>
            </div>
          </div>
        </section>

        <!-- our values -->
        <section class="mt-16">
          <div class="h-full flex justify-center">
            <div class="beam-container pt-10 pb-5">
              <div class="beam-h2 text-center mb-3">Our values</div>
              <div
                class="flex flex-col sm:flex-row sm:flex-wrap lg:flex-nowrap pt-8 gap-4 justify-between"
              >
                <div
                  class="sm:w-[calc(50%-0.5rem)] lg:w-72 h-72 bg-white border rounded-xl relative overflow-hidden"
                >
                  <div
                    class="absolute w-[671px] h-[671px] left-1/2 -translate-x-1/2 bottom-[190px] rounded-[60%] bg-navy-800"
                  ></div>
                  <div class="w-full flex justify-center">
                    <img
                      class="relative top-7"
                      :src="`/icons/lightning-icon.svg`"
                      alt="lightning icon"
                    />
                  </div>
                  <div
                    class="relative z-10 h-full w-full px-5 flex flex-col items-center justify-center"
                  >
                    <p
                      class="text-xl mt-8 w-3/6 text-balance font-bold text-center"
                    >
                      We make an impact
                    </p>
                    <p class="text-base text-center mt-2">
                      We change lives and fix social problems by setting and
                      achieving ambitious targets
                    </p>
                  </div>
                </div>

                <div
                  class="w-full sm:w-[calc(50%-0.5rem)] lg:w-72 h-72 bg-white border rounded-xl relative overflow-hidden"
                >
                  <div
                    class="absolute w-[671px] h-[671px] left-1/2 -translate-x-1/2 bottom-[190px] rounded-[60%] bg-navy-800"
                  ></div>
                  <div class="w-full flex justify-center">
                    <img
                      class="relative top-7"
                      :src="`/icons/chat-icon.svg`"
                      alt="chat icon"
                    />
                  </div>
                  <div
                    class="relative z-10 h-full w-full flex flex-col px-5 items-center justify-center"
                  >
                    <p class="text-xl mt-8 text-balance font-bold text-center">
                      We're open and transparent
                    </p>
                    <p class="text-base text-center mt-2">
                      We communicate openly and transparently with each other
                      and the world
                    </p>
                  </div>
                </div>

                <div
                  class="w-full sm:w-[calc(50%-0.5rem)] lg:w-72 h-72 bg-white border rounded-xl relative overflow-hidden"
                >
                  <div
                    class="absolute w-[671px] h-[671px] left-1/2 -translate-x-1/2 bottom-[190px] rounded-[60%] bg-navy-800"
                  ></div>
                  <div class="w-full flex justify-center">
                    <img
                      class="relative top-7"
                      :src="`/icons/community-icon.svg`"
                      alt="community icon"
                    />
                  </div>
                  <div
                    class="relative z-10 h-full w-full flex flex-col px-5 items-center justify-center"
                  >
                    <p
                      class="text-xl mt-8 w-4/6 text-balance font-bold text-center"
                    >
                      We get better together
                    </p>
                    <p class="text-base text-center mt-2">
                      We support each other to improve and actively work on
                      personal growth - with no ego
                    </p>
                  </div>
                </div>

                <div
                  class="w-full sm:w-[calc(50%-0.5rem)] lg:w-72 h-72 bg-white border rounded-xl relative overflow-hidden"
                >
                  <div
                    class="absolute w-[671px] h-[671px] left-1/2 -translate-x-1/2 bottom-[190px] rounded-[60%] bg-navy-800"
                  ></div>
                  <div class="w-full flex justify-center">
                    <img
                      class="relative top-7"
                      :src="`/icons/sun-icon.svg`"
                      alt="sun icon"
                    />
                  </div>
                  <div
                    class="relative z-10 h-full w-full flex flex-col px-5 items-center justify-center"
                  >
                    <p class="text-xl mt-2 text-balance font-bold text-center">
                      We're beams of energy and focus
                    </p>
                    <p class="text-base text-center mt-2">
                      We bring energy and strive for laser focus in our work
                    </p>
                  </div>
                </div>
              </div>
              <div class="w-full flex justify-center">
                <a
                  class="button button-tertiary button-large mt-4 text-base"
                  href="https://docs.google.com/document/d/1W2pEQg2sSnU1KBaJp5gX6cWowLjSoDmn/edit#heading=h.2d073sz5a7m2"
                  target="_blank"
                  >Read our culture deck</a
                >
              </div>
            </div>
          </div>
        </section>

        <!-- Where we call home -->
        <section class="mt-24">
          <div class="beam-container flex flex-col items-center mt-6">
            <h2 class="beam-h2 text-center mb-3">Where we call home</h2>
            <div
              class="text-center text-pretty md:w-4/6 w-10/12 mb-7 text-base md:text-xl"
            >
              We have a bright and airy office in Hoxton, Shoreditch with a
              barista, roof terrace and regular social events. London-based
              teams come together twice a week, with the option to work anywhere
              in the UK the rest of the time.
            </div>
          </div>
          <div class="beam-container">
            <div class="flex flex-col sm:flex-row gap-4">
              <div class="w-full">
                <img
                  class="rounded-lg"
                  :src="whereWeCallHomeUrl"
                  alt="Hoxton office"
                  ref="officeImage"
                />
              </div>
              <div class="w-full" id="mapContainer">
                <img
                  id="backupMapImage"
                  :src="beamMapBackupUrl"
                  alt="Map of Shoreditch Exchange"
                  style="display: block; border-radius: 10px"
                />
                <iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2482.078286019169!2d-0.08024850653540175!3d51.53012393638603!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x48761dd9fa3cf3eb%3A0x448af2d9c1109faf!2sShoreditch%20Exchange!5e0!3m2!1sen!2suk!4v1726658022859!5m2!1sen!2suk&disableDefaultUI=true&zoomControl=false&mapTypeControl=false&streetViewControl=false&fullscreenControl=false"
                  width="540"
                  height="300"
                  ref="mapIframe"
                  style="border: 1; border-radius: 10px"
                  allowfullscreen=""
                  loading="lazy"
                  referrerpolicy="no-referrer-when-downgrade"
                  onload="this.style.display='block'; document.getElementById('backupMapImage').style.display='none';"
                  onerror="this.style.display='none'; document.getElementById('backupMapImage').style.display='block';"
                ></iframe>
              </div>
            </div>
          </div>
        </section>

        <!-- Benefits -->
        <section class="mt-16 sm:mt-20 lg:mt-32">
          <h2 class="beam-h2 text-center">Our perks</h2>
          <div class="md:beam-container w-full h-full flex justify-center mt-6">
            <div
              class="w-full h-fit flex flex-col md:flex-row bg-white border rounded-none md:rounded-xl"
            >
              <div
                class="flex-1 flex flex-col px-5 md:pl-10 pt-5 md:pt-10 pb-0 md:pb-10"
              >
                <EmojiAvatarBlock
                  emoji="🚀"
                  title="Meaningful work"
                  description="Do work that matters to the world, day-in day-out"
                />
                <EmojiAvatarBlock
                  emoji="💷"
                  title="Competitive salary"
                  description="We offer market-leading salaries in social impact"
                />
                <EmojiAvatarBlock
                  emoji="📈"
                  title="Share options"
                  description="Be a Beam owner with EMI-qualifying share options"
                />
                <EmojiAvatarBlock
                  emoji="🏖️"
                  title="Annual retreat"
                  description="Bond with teammates on a yearly team retreat"
                />
                <EmojiAvatarBlock
                  emoji="💻"
                  title="New laptop"
                  description="Choose between a MacBook or PC - the choice is yours"
                />
                <EmojiAvatarBlock
                  emoji="💆🏽‍♀️"
                  title="Personal budget"
                  description="£200 a year to spend on anything wellbeing"
                />
                <EmojiAvatarBlock
                  emoji="🍜"
                  title="Monthly team lunch"
                  description="A different cuisine each month"
                />
              </div>
              <div
                class="flex-1 flex flex-col pl-5 md:pl-10 md:pt-10 md:pb-10 sm:pb-0 md:pr-10"
              >
                <EmojiAvatarBlock
                  emoji="🌴"
                  title="Generous holiday"
                  description="Up to 30 days per year excluding Bank holidays"
                />
                <EmojiAvatarBlock
                  emoji="💬"
                  title="Team talks"
                  description="Network with big name tech entrepreneurs"
                />
                <EmojiAvatarBlock
                  emoji="🧘‍♂️"
                  title="Coaching & wellness"
                  description="Access to Oliva’s wellbeing support and Calm"
                />
                <EmojiAvatarBlock
                  emoji="🤝"
                  title="Financial wellbeing"
                  description="1:1 video calls with professional finance experts"
                />
                <EmojiAvatarBlock
                  emoji="🏢"
                  title="Hybrid working"
                  description="Work at our beautiful Hoxton office or at home"
                />
                <EmojiAvatarBlock
                  emoji="🚴"
                  title="Bikes &amp; tech"
                  description="Big discounts via Cyclescheme &amp; Techscheme"
                />
                <EmojiAvatarBlock
                  emoji="☕️"
                  title="Fantastic coffee"
                  description="Our office has a barista on-hand for your caffeine fix"
                />
              </div>
            </div>
          </div>
        </section>

        <!-- Open Positions -->
        <section>
          <career-positions></career-positions>
        </section>

        <!-- FAQs -->
        <section>
          <div class="w-full h-full justify-center items-center pb-12 px-4">
            <faq-section
              bgColor="bg-white"
              :defaultFaqs="frequentlyAskedQuestions"
            ></faq-section>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>
<script>
import { onMounted } from "vue"

onMounted(() => {
  if (window.location.hash) {
    const targetElement = document.querySelector(window.location.hash)
    if (targetElement) {
      setTimeout(function () {
        targetElement.scrollIntoView({ behavior: "smooth", block: "start" })
      }, 300)
    }
  }
})
export default {
  data() {
    return {
      isSmallScreen: false,
    }
  },
  props: {
    testimonials: { type: Array },
    galleryImages: { type: Array },
    heroImageUrl: { type: String },
    landingLeft1Url: { type: String },
    landingLeft2Url: { type: String },
    landingLeft3Url: { type: String },
    whereWeCallHomeUrl: { type: String },
    beamMapBackupUrl: { type: String },
    frequentlyAskedQuestions: {
      type: Array,
      default: () => [],
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.checkScreenSize()
      window.addEventListener("resize", this.checkScreenSize)
      window.addEventListener("resize", this.resizeIframe)

      const overlay = document.querySelector(".video-overlay")
      const iframe = document.querySelector("iframe")

      overlay.addEventListener("click", function () {
        const src = iframe.src
        iframe.src = src + "&autoplay=1"
        overlay.style.display = "none"
      })
    })
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenSize)
    window.removeEventListener("resize", this.resizeIframe)
  },

  computed: {
    ellipsisBorderStyle() {
      return {
        borderTopLeftRadius: `100% ${this.isSmallScreen ? "15%" : "40%"}`,
        borderTopRightRadius: `100% ${this.isSmallScreen ? "15%" : "40%"}`,
      }
    },
  },
  methods: {
    checkScreenSize() {
      this.isSmallScreen = window.innerWidth < 768
    },
    resizeIframe() {
      const officeImage = this.$refs.officeImage
      const mapIframe = this.$refs.mapIframe
      if (officeImage && mapIframe) {
        mapIframe.width = officeImage.offsetWidth
        mapIframe.height = officeImage.offsetHeight
      }
    },
  },
}
</script>
